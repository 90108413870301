<template>
  <div class="Userhl_ygkecheng">
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动名称</div>
          <div class="fxiangmu_toplir">
            <el-input v-model="hd_name" placeholder="请输入活动名称"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">主办方</div>
          <div class="fxiangmu_toplir">
            <el-input v-model="hd_zb" placeholder="请输入主办方"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="false">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动类型</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="hdtype" placeholder="请选择活动类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">专业分类</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="zhuanyeval" placeholder="请选择专业分类">
              <el-option
                v-for="item in zyoptions"
                :key="item.classid"
                :label="item.classname"
                :value="item.classid"
              >
              </el-option>
              <el-option label="其他" value="0"> </el-option>
            </el-select>
            <el-select
              style="margin-left: 2rem"
              v-model="zhuanyeval_sm"
              placeholder="请选择专业细分"
              v-if="zhuanyeval_smtype"
            >
              <el-option
                v-for="item in zyoptionssmall"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动形式</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="hdtype_xs" label="1">线上</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="hdtype_xs" label="2">线下</el-radio>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">付费类型</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="fufei" label="1">收费</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="fufei" label="0">免费</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="hdmoney_t">费用</div>
            <div class="fxiangmu_toplir_weituobox" v-if="hdmoney_t">
              <el-input v-model="hdmoney" placeholder="请输入费用"></el-input>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">参与方式</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="canyutype" placeholder="请选择参与方式">
              <el-option
                v-for="item in canyuall"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil fxiangmu_toplilwz">*</div>
          <div class="fxiangmu_toplic fxiangmu_toplilwz">活动网址</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="activity_url"
              placeholder="请输入活动网址"
              style="height: auto"
            >
              <template slot="prepend">Https://</template>
            </el-input>
          </div>
        </li>
      </ul>
    </div>
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli" v-if="hd_dizhitype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动地址</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="hd_dizhi"
              placeholder="请输入活动地址"
            ></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动人数</div>
          <div class="fxiangmu_toplir">
            <el-input v-model="hd_num" placeholder="请输入活动人数"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动时间</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_datebox">
            <div class="fxiangmu_toplir_date">
              <el-date-picker
                v-model="startend"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
            <div class="fxiangmu_toplir_datetext">报名截止时间</div>
            <div class="fxiangmu_toplir_date">
              <el-date-picker
                v-model="bmend"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动封面</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn hdfengmian">
            <img :src="fmimg" alt="" />
            <el-button plain icon="el-icon-picture">
              上传封面
              <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">活动介绍</div>
          <div class="fxiangmu_toplir">
            <div id="wangeditor"></div>
          </div>
        </li>
      </ul>
    </div>
    <el-button class="xiangmusub_btn" type="primary" plain @click="subhd"
      >发布活动</el-button
    >
  </div>
</template>
<script>
import $ from "jquery";
import wangeditor from "wangeditor";
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: { Uploadimg },
  data() {
    return {
      imgalls: {
        name: "",
        width: 400,
        height: 400,
      },
      editor: null,
      zhuanyeval: "",
      zhuanyeval_sm: "",
      zyoptions: [],
      zyoptionssmall: [],
      zhuanyeval_smtype: false,
      options: [
        {
          value: "1",
          label: "沙龙",
        },
        {
          value: "2",
          label: "竞赛",
        },
        {
          value: "3",
          label: "展评",
        },
        {
          value: "4",
          label: "专题",
        },
      ],
      canyuall: [
        {
          value: "1",
          label: "报名参与",
        },
        {
          value: "2",
          label: "购票参与",
        },
        {
          value: "3",
          label: "直接参与",
        },
      ],
      hd_dizhitype: false, //活动地址展示
      hdmoney_t: true, //收费展示
      hd_name: "", //活动名称
      hd_zb: "", //主办方
      hdtype: "1", //活动类型
      hdtype_xs: "1", //活动i形式
      fufei: "1", //付费类型
      hdmoney: "", //收费金额
      canyutype: "", //参与方式
      hd_num: "", //活动人数
      startend: "", //活动时间
      bmend: "", //报名截止时间
      fmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //封面地址
      hdall: "", //活动介绍
      hd_dizhi: "", //活动地址
      activity_url: "", //活动网址
      userinfo: {},
    };
  },
  methods: {
    uploadimgurl(rul) {
      this.fmimg = rul;
      console.log("000");
    },
    subhd() {
      var that = this;
      
      if (that.hd_name == "") {
        that.$message("请填写活动名称");
        return false;
      }
      if (that.hd_zb == "") {
        that.$message("请填写主办方");
        return false;
      }
      if (that.hdtype == "") {
        that.$message("请选择专业分类");
        return false;
      }
      if (that.canyutype == "") {
        that.$message("请选择参与方式");
        return false;
      }
      if (that.activity_url == "") {
        that.$message("请输入活动网址");
        return false;
      }
      if (that.hd_num == "") {
        that.$message("请输入活动人数");
        return false;
      }
      if (that.hdtype_xs == "2") {
        if (that.hd_dizhi == "") {
          that.$message("请填写活动地址");
          return false;
        }
      };
      if (that.startend == "") {
        that.$message("请输入活动时间");
        return false;
      }
      if (that.bmend == "") {
        that.$message("请输入活动报名截止时间");
        return false;
      }
      if (
        that.fmimg ==
        "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
      ) {
        that.$message("请选择活动封面");
        return false;
      }
      if (this.editor.txt.html() == "") {
        that.$message("请输入活动介绍");
        return false;
      }
      var drawtext = this.editor.txt.html();
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getActivityAdd",
        method: "post",
        data: {
          deadline: that.bmend,
          title: that.hd_name,
          a_type: that.hdtype, //专业分类 沙龙 竞赛 展评 专题  （此类已砍）
          a_shape: that.hdtype_xs,
          activity_one: that.zhuanyeval,
          activity_two: that.zhuanyeval_sm,
          way: that.canyutype,
          title_pic: that.fmimg,
          draw_start: that.startend[0],
          draw_end: that.startend[1],
          host_unit: that.hd_zb,
          link_man: "",
          phone: "",
          drawtext,
          pay_state: that.fufei,
          money: that.hdmoney,
          address: that.hd_dizhi,
          number: that.hd_num,
          activity_url: that.activity_url,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.$message(res.data.messages);
          this.$router.push({ path: "/Userhome/Userhl_mezp" });
        } else {
          that.$message(res.data.messages);
        }
      });
    },
    bigaxios() {
      this.$axios({
        url: "/api/getClassTopList",
        method: "post",
        data: {
          class_sort: "1",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.zyoptions = res.data.result;
        } else {
        }
      });
    },
    smaxios(classid) {
      this.$axios({
        url: "/api/getClassList",
        method: "post",
        data: {
          class: classid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.zyoptionssmall = res.data.result;
        } else {
        }
      });
    },
    chantu(resultFiles, insertImgFn) {
      var formData = new FormData();
      formData.append("file", resultFiles[0]);
      this.$axios({
        url: "https://api.hozoin.cn/api/UploadFile/upImg",
        headers: {
          "X-CSRF-TOKEN": this.userinfo.token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        type: "post",
        data: formData,
        method: "post",
      }).then((res) => {
        if (res.data.code > 0) {
          insertImgFn(res.data.result);
        }
      });
    },
  },
  watch: {
    fufei: function () {
      if (this.fufei == "0") {
        this.hdmoney_t = false;
      } else {
        this.hdmoney_t = true;
      }
    },
    hdtype_xs: function () {
      if (this.hdtype_xs == "1") {
        this.hd_dizhitype = false;
      } else {
        this.hd_dizhitype = true;
      }
    },
    zhuanyeval() {
      if (this.zhuanyeval != 0) {
        this.zyoptionssmall = [];
        this.zhuanyeval_smtype = true;
        this.zhuanyeval_sm = "";
        this.smaxios(this.zhuanyeval);
      } else {
        this.zyoptionssmall = [];
        this.zhuanyeval_sm = 0;
        this.zhuanyeval_smtype = false;
      }
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.bigaxios();
  },
  mounted() {
    this.editor = new wangeditor("#wangeditor");
    this.editor.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "quote",
      "emoticon",
      "image",
      "table",
      "splitLine",
      "undo",
      "redo",
    ];
    var that = this;
    this.editor.config.showLinkImg = false;
    this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      that.chantu(resultFiles, insertImgFn);
    };
    this.editor.create();
  },
};
</script>
