<template>
  <div class="Sjfabu">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="label1" name="first" v-if="label1type">
        <div>
          <Userhlfavideo v-if="Userhlfavideoif" />
          <Userhlfaxiangmu v-if="Userhlfaxiangmuif" />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="label2" name="two" v-if="Userhlfavideoif">
        <div>
          <Userhlfahuodong />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="label3" name="three">
        <div>
          <Userhlshangchuan />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="label4" name="four" v-if="Userhlfavideoif">
        <div>
          <Userhlfazp />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Userhlfavideo from "./Userhomeleft/Userhl_favideo.vue";
import Userhlfazp from "./Userhomeleft/Userhl_fazp.vue";
import Userhlshangchuan from "./Userhomeleft/Userhl_shangchuan.vue";
import Userhlfaxiangmu from "./Userhomeleft/Userhl_faxiangmu.vue";
import Userhlfahuodong from "./Userhomeleft/Userhl_fahuodong.vue";
export default {
  components: {
    Userhlfavideo,
    Userhlfazp,
    Userhlshangchuan,
    Userhlfaxiangmu,
    Userhlfahuodong,
  },
  data() {
    return {
      activeName: "first",
      label1: "",
      label2: "活动",
      label3: "作品",
      label4: "展览",
      Userhlfavideoif: false,
      Userhlfaxiangmuif: false,
      label1type: false,
      userinfo: {},
    };
  },
  methods: {
    handleClick(tab, event) {
      //   console.log(tab, event);
    },
  },
  created() {
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;
    // var userinfo = {
    //   groupid: 3, //假状态
    // };
    if (userinfo) {
      if (userinfo.groupid == 1) {
        that.label1type = false;
        that.activeName = "three";
      } else if (userinfo.groupid == 2) {
        that.label1 = "任务";
        that.Userhlfaxiangmuif = true;
        that.label1type = true;
      } else if (userinfo.groupid == 3) {
        that.label1 = "视频";
        that.Userhlfavideoif = true;
        that.label1type = true;
      } else if (userinfo.groupid == 4) {
        // 专家预留
      }
    };
    var zpid = that.$route.query.data;
    var usertypeid=that.$route.query.usertypeid;//用户身份识别id
    var bjtypeid=that.$route.query.bjtypeid;//编辑类型 作品:'zuopin'
    if(zpid==undefined){
      // console.log("正常发布，非编辑")
    }else{
      // 编辑处理
        that.activeName="three"
    };
  },
};
</script>
<style>
@import "../assets/css/Sjfabu.css";
</style>
